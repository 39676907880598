import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import { getUserInfo } from "../utils/user.util";
import Shops from "../_metronic/components/shop/Shops";
import Footericon from "../_metronic/components/shop/Footericon/Footericon";
import Aboutus from "../_metronic/components/shop/Aboutus/Aboutus";
import ContactUs from "../_metronic/components/ContactUs/ContactUs";

export default function BasePage() {
  const UserInfo = getUserInfo();
  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {UserInfo?.role === "superadmin" ? (
            <>
              <Redirect exact from="/" to="/contactus" />
              <ContentRoute
                exact
                path="/shops"
                component={Shops}
                children={undefined}
                render={undefined}
              />
                <ContentRoute
                exact
                path="/footericon"
                component={Footericon}
                children={undefined}
                render={undefined}
              />
                <ContentRoute
                exact
                path="/aboutus"
                component={Aboutus}
                children={undefined}
                render={undefined}
              />
                <ContentRoute
                exact
                path="/contactus"
                component={ContactUs}
                children={undefined}
                render={undefined}
              />
            </>
          ) : (
            <>
              <Redirect exact from="/" to="/dashboard" />
              {/* <Redirect to="error/error-v6" /> */}
              {/* <ContentRoute
                exact
                path="/shops"
                component={Shops}
                children={undefined}
                render={undefined}
              /> */}
            </>
          )}
          <Redirect to="error/error-v6" />
        </Switch>
      </Suspense>
    </>
  );
}
