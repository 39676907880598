/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { getUserInfo } from "../../../../../utils/user.util";

export function AsideMenuList({ layoutProps }: any) {
  const location = useLocation();
  let userInfo = getUserInfo();
  const getMenuItemActive = (url: any, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {userInfo?.role === "superadmin" && (
          <>
            {/* <li className={`menu-item ${getMenuItemActive("/shops", false)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/shops">
                <span className="svg-icon menu-icon">
                  <i className="fa-solid fa-building" style={{ color: "#383839" }}></i>
                </span>
                <span className="menu-text">Shops</span>
              </NavLink>
            </li> */}
            <li className={`menu-item ${getMenuItemActive("/footericon", false)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/footericon">
                <span className="svg-icon menu-icon">
                  <i className="fa fa-share-square" style={{ color: "#383839" }}></i>
                </span>
                <span className="menu-text">Social Media</span>
              </NavLink>
            </li>
            <li className={`menu-item ${getMenuItemActive("/contactus", false)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/contactus">
                <span className="svg-icon menu-icon">
                  <i className="fa fa-mobile" style={{ color: "#383839" }}></i>
                </span>
                <span className="menu-text">Anfrage</span>
              </NavLink>
            </li>
            <li className={`menu-item ${getMenuItemActive("/aboutus", false)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/aboutus">
                <span className="svg-icon menu-icon">
                  <i className="fa fa-newspaper-o" style={{ color: "#383839" }}></i>
                </span>
                <span className="menu-text">News Beiträge</span>
              </NavLink>
            </li>
          </>
        )}
        {/* {userInfo?.role === "admin" && (
          <>
            <li className={`menu-item ${getMenuItemActive("/shops", false)}`} aria-haspopup="true">
              <NavLink className="menu-link" to="/shops">
                <span className="svg-icon menu-icon">
                  <i className="fa-solid fa-building" style={{ color: "#383839" }}></i>
                </span>
                <span className="menu-text">Shops</span>
              </NavLink>
            </li>
          </>
        )} */}
      </ul>
    </>
  );
}
