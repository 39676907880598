import React, { useEffect, useState , useRef} from "react";
import { title } from "process";
import { Button, Modal } from "react-bootstrap";
import DataTable, { defaultThemes } from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../../../helpers/API/ApiData";
import { Dialog, IconButton, List, Slide, Toolbar, Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { ThreeCircles } from "react-loader-spinner";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import moment from "moment";

const Transition: any = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Aboutus = () => {
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const [isEditApi, setIsEditApi] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [countPerPage, setCountPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [filteredData, setFilteredData] = useState<any>({});
  const [idForUpdate, setIdForUpdate] = useState<string>("");
  const [idForDelete, setIdForDelete] = useState<string>("");
  const [inputValue, setInputValue] = useState<any>({});
  const [filterData, setFilterData] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [journals, setJournalData] = useState<any>("");
  const editor = useRef();

  const getSunEditorInstance = (sunEditor : any) => {
    editor.current = sunEditor;

  };

  console.log("journals",journals)

  useEffect(() => {
    title === "HRE | Dashboard"
      ? (document.title = title)
      : (document.title = "HRE | News Beiträge");
  }, []);

  useEffect(() => {
    getAll();
  }, [page, countPerPage]);

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleOnChangeImg = (e: any) => {
    const { name } = e.target;
    setInputValue({ ...inputValue, [name]: e.target.files[0] });
    setErrors({ ...errors, [name]: "" });
  };

  const handleClose = () => {
    setInputValue({});
    setErrors({});
    setIsAdd(false);
    setIsEditApi(false);
  };

  const handleCloseDelete = () => {
    setShow(false);
  };

  const getAll = async () => {
    setIsLoaderVisible(true);
    await ApiGet(`banner/getAllBanner?isActive=true`)
      .then((res: any) => {
        setIsLoaderVisible(false);
        setFilteredData(res?.data?.payload?.contact);
        setFilterData(res?.data?.payload?.contact);
        setCount(res?.data?.payload?.contact.length);
      })
      .catch((err) => {});
  };
console.log("handleOnChangeImg",inputValue.image)
  const validateFormForAdd = () => {
    let formIsValid = true;
    let errors: any = {};

    if (inputValue && !inputValue.title?.trim()) {
      formIsValid = false;
      errors["title"] = "*Bitte Titel eingeben!";
    }
    if (inputValue && !inputValue.header?.trim()) {
      formIsValid = false;
      errors["header"] = "*Bitte Kopfzeile eingeben!";
    }
    if (!journals) {
      formIsValid = false;
      errors["journals"] = "*Bitte Beschreibung eingeben!";
    }
    if (inputValue && !inputValue.pname?.trim()) {
      formIsValid = false;
      errors["pname"] = "*Bitte Personenname!";
    }
    if (inputValue && !inputValue.cYear?.trim()) {
      formIsValid = false;
      errors["cYear"] = "*Bitte Firmenjahr!";
    }
    if (inputValue && !inputValue.cYeartwo?.trim()) {
      formIsValid = false;
      errors["cYeartwo"] = "*Bitte Firmenjahr two!";
    }
    if (inputValue && !inputValue?.image) {
      formIsValid = false;
      errors["image"] = "*Bitte Bild hochladen!";
    }
   

    

    setErrors(errors);
    return formIsValid;
  };

  const validateFormUpdate = () => {
    let formIsValid = true;
    let errors: any = {};
    // if (inputValue && !inputValue.title) {
    //   formIsValid = false;
    //   errors["name"] = "*Please enter link!";
    // }
    if (inputValue && !inputValue.title?.trim()) {
      formIsValid = false;
      errors["title"] = "*Bitte Titel eingeben!";
    }
    if (inputValue && !inputValue.header?.trim()) {
      formIsValid = false;
      errors["header"] = "*Bitte Kopfzeile eingeben!";
    }
    if (!journals.trim()) {
      formIsValid = false;
      errors["journals"] = "*Bitte Beschreibung eingeben!";
    }
    if (inputValue && !inputValue.pname?.trim()) {
      formIsValid = false;
      errors["pname"] = "*Bitte Personenname!";
    }
    if (inputValue && !inputValue.cYear?.trim()) {
      formIsValid = false;
      errors["cYear"] = "*Bitte Firmenjahr!";
    }
    if (inputValue && !inputValue.cYeartwo?.trim()) {
      formIsValid = false;
      errors["cYeartwo"] = "*Bitte Firmenjahr two!";
    }
    if (inputValue && !inputValue.image) {
      formIsValid = false;
      errors["image"] = "*Bitte Bild hochladen!";
    }
   
    setErrors(errors);
    return formIsValid;
  };

  const handleAdd = (e: any) => {
    e.preventDefault();
    if (validateFormForAdd()) {
      setLoading(true);
      let formData = new FormData();
      formData.append("field1", inputValue?.header.trim());
      formData.append("field2", inputValue?.title.trim());

      formData.append("field3", journals);

      formData.append("field4", inputValue?.pname.trim());

      formData.append("field5", inputValue?.cYear.trim());
      formData.append("field6", inputValue?.cYeartwo.trim());
      formData.append("image", inputValue?.image);

      


      ApiPost(`banner/addBanner`, formData)
        .then((res: any) => {
          if (res?.status == 200) {
            console.log("adddbannrrrr",res);

            setIsAdd(false);
            toast.success("Erfolgreich erstellt");
            setInputValue({});
            getAll();

            setLoading(false);
          } else {
            toast.error(res?.data?.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error(err.message);
          setLoading(false);
        });
    }
  };
console.log("inputValue?.imageForUpdate",inputValue?.imageForUpdate && URL.createObjectURL(inputValue?.imageForUpdate) , inputValue?.image)
  const handleUpdate = (e: any) => {
    e.preventDefault();
    if (validateFormUpdate()) {
      setLoading(true);
      let formData = new FormData();
      // formData.append("link", inputValue?.title);
      // formData.append("name", inputValue?.iconname);
      formData.append("field1", inputValue?.header.trim());
      formData.append("field2", inputValue?.title.trim());

      formData.append("field3", journals);

      formData.append("field4", inputValue?.pname.trim());

      formData.append("field5", inputValue?.cYear.trim());
      formData.append("field6", inputValue?.cYeartwo.trim());
      // formData.append("image", inputValue?.image);

      inputValue?.imageForUpdate && formData.append("image", inputValue?.imageForUpdate);
      
      ApiPut(`banner/updateBanner/${idForUpdate}`, formData)
        .then((res: any) => {
          if (res?.status == 200) {
            setInputValue({});
            setIsAdd(false);
            setIsEditApi(false);
            toast.success("Erfolgreich geupdated");
            getAll();
            setLoading(false);
          } else {
            toast.error(res?.data?.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          setLoading(false);
        });
    }
  };

  const handleDelete = () => {
    let formData = new FormData();
    formData.append("isActive", "false");
    ApiPut(`banner/updateBanner/${idForDelete}` ,formData)
      .then((res: any) => {
        if (res?.status == 200) {
          setShow(false);
          toast.success("Erfolgreich gelöscht");
          getAll();
          setPage(1);
          setCount(0);
          setCountPerPage(countPerPage);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const columns: any = [
    {
      name: "SNo",
      cell: (row: any, index: any) => (page - 1) * countPerPage + (index + 1),
      width: "65px",
    },
    {
      name: "Datum",
      // cell: (row) => {
      //   return <span>{ausTimeZone(row?.createdAt, "ll")}</span>;
      // },
      selector: (row: any) => moment.utc(row.createdAt,"YYYY-MM-DD\THH:mm:ss\Z").format("YYYY-MM-DD"),
      sortable: true,
    },
    {
      name: "Header",
      selector: "field1",
      // selector: (row: any) => row?.name,
      cell: (row: any) => {
        return (
          <>
            <p
              dangerouslySetInnerHTML={{
                __html: row?.field1,
              }}
              className=""
            />
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Titel",
      selector: "field2",
      // selector: (row: any) => row?.name,
      cell: (row: any) => {
        return (
          <>
            <p
              dangerouslySetInnerHTML={{
                __html: row?.field2,
              }}
              className=""
            />
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Name der Person",
      selector: "field4",
      // selector: (row: any) => row?.name,
      cell: (row: any) => {
        return (
          <>
            <p
              dangerouslySetInnerHTML={{
                __html: row?.field4,
              }}
              className=""
            />
          </>
        );
      },
      sortable: true,
    },
   
 
    {
      name: "Bild",
      //   selector: "photo",
      cell: (row: any) => {
        return (
          <>
            <div className="p-3">
              <img className="max-w-50px zoom" alt="img" src={row?.image} />
            </div>
          </>
        );
      },
      wrap: true,
    },
    {
      name: "Aktionen",
      cell: (row: any) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  setIsAdd(true);
                  setIsEditApi(true);
                  setInputValue({
                    header: row?.field1,
                    title: row?.field2,
                    journals: row?.field3,
                    pname: row?.field4,
                    cYear: row?.field5,
                    cYeartwo: row?.field6,
                    image: row?.image,

                  });
                  setIdForUpdate(row?._id);
                }}
              >
                <Tooltip title="Bearbeiten" arrow>
                  <CreateIcon />
                </Tooltip>
              </div>
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                setShow(true);
                setIdForDelete(row?._id);
              }}
            >
              <Tooltip title="Löschen" arrow>
                <DeleteIcon />
              </Tooltip>
            </div>
          </>
        );
      },
    },
  ];

  const handleSearch = (e: any) => {
    var value = e.target.value.toLowerCase();
    setFilteredData(() =>
      filterData.filter((item: any) => item?.name?.toLowerCase()?.includes(value))
    );
  };

  // * Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  return (
    <>
      <div className="card p-1">
        {document.title === "HRE | News Beiträge" && <ToastContainer />}
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">News Beiträge</h2>
            </div>
            <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  name="search"
                  // value={search}
                  placeholder="News-Beiträge suchen"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            </div>
            <div className="cus-medium-button-style button-height">
              <button
                onClick={() => {
                  setIsAdd(true);
                }}
                className="btn btn-success mr-2"
              >
                Hinzufügen News Beiträge
              </button>
            </div>
          </div>

          {/* delete model */}
          <Modal show={show} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
              <Modal.Title className="text-danger">Alarm!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Möchten Sie diese News wirklich löschen?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDelete}>
              Abbrechen
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  handleDelete();
                }}
              >
                Löschen
              </Button>
            </Modal.Footer>
          </Modal>
          {/* end delete model */}

          <DataTable
            columns={columns}
            data={filteredData}
            customStyles={customStyles}
            style={{
              marginTop: "-3rem",
            }}
            progressPending={isLoaderVisible}
            progressComponent={<ThreeCircles color="#334D52" height={30} width={30} />}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        </div>
      </div>

      {isAdd ? (
        <Dialog fullScreen open={isAdd} onClose={handleClose} TransitionComponent={Transition}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isAdd === true && (
              <div className="form ml-30 ">
               
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Kopfzeile eingeben</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="header"
                        name="header"
                        value={inputValue.header}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["header"]}
                    </span>
                  </div>
                </div>
                
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Titel eingeben</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="title"
                        name="title"
                        value={inputValue.title}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["title"]}
                    </span>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Beschreibung eingeben</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      {/* <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="description"
                        name="description"
                        value={inputValue.description}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      /> */}
                      <SunEditor
                      autoFocus={true}
                  placeholder="hier eingeben..."
                  lang="de"
                  name="my-editor"
                  defaultValue={ ""}
                  getSunEditorInstance={getSunEditorInstance}
                  setOptions={{
                    // height: 200,
                    // buttonList: buttonList.formatting,
                    // buttonList: buttonList.basic, // Or Array of button list, eg. [['font', 'align'], ['image']]
                    buttonList: buttonList.complex, // Or Array of button list, eg. [['font', 'align'], ['image']]
                    // Other option
                  }}
                  setDefaultStyle="borderRadius:4px"
                  onChange={(text) => setJournalData(text)}
                  // maxLength={8000}
                />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["journals"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Name der Person</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="pname"
                        name="pname"
                        value={inputValue.pname}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["pname"]}
                    </span>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Geben Sie das Firmenjahr ein</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="cYear"
                        name="cYear"
                        value={inputValue.cYear}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["cYear"]}
                    </span>
                  </div>
                </div>
                
               
                 <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Platz</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="cYeartwo"
                        name="cYeartwo"
                        value={inputValue.cYeartwo}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["cYeartwo"]}
                    </span>
                  </div>
                </div>
                
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">Bild</label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="file"
                        className={`form-control form-control-lg form-control-solid `}
                        name={idForUpdate ? "imageForUpdate" : "image"}
                        onChange={(e) => {
                          handleOnChangeImg(e);
                        }}
                        accept="image/*"
                        required
                      />
                    </div>
                      {inputValue?.imageForUpdate ? <div><img
                              style={{
                                height: "128px",
                                width: "128px",
                                margin: "15px 0",
                                objectFit: "contain",
                              }}
                              src={
                                inputValue?.imageForUpdate && URL.createObjectURL(inputValue?.imageForUpdate)

                              }
                              alt=""
                            /></div>
                          :
                    <div>
                      {inputValue?.image && (
                        <img
                          style={{
                            height: "128px",
                            width: "128px",
                            margin: "15px 0",
                            objectFit: "contain",
                          }}
                          src={
                            inputValue?.image?.toString()?.includes(".com") === true
                              ? inputValue?.image
                              : URL.createObjectURL(inputValue?.image)
                          }
                          alt=""
                        />
                      )}
                    </div>}

                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["image"]}
                    </span>
                  </div>
                </div>
                
                {isEditApi && !inputValue?.imageForUpdate && (
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">Hochgeladenes Bild</label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <div>
                          {inputValue?.image && (
                            <img
                              style={{
                                height: "128px",
                                width: "128px",
                                margin: "15px 0",
                                objectFit: "contain",
                              }}
                              src={
                                inputValue?.image?.toString()?.includes(".com") === true
                                  ? inputValue?.image
                                  : URL.createObjectURL(inputValue?.image)
                              }
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="d-flex align-items-center justify-content-center">
                  {loading ? (
                    <button className="btn btn-success mr-2">
                      <span>{isEditApi ? "Bearbeiten" : "Hinzufügen"} News Beiträge</span>
                      {loading && <span className="mx-3 spinner spinner-white"></span>}
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                        isEditApi ? handleUpdate(e) : handleAdd(e);
                      }}
                      className="btn btn-success mr-2"
                    >
                      <span>{isEditApi ? "Bearbeiten" : "Hinzufügen"} News Beiträge</span>
                      {loading && <span className="mx-3 spinner spinner-white"></span>}
                    </button>
                  )}
                </div>
              </div>
            )}
          </List>
        </Dialog>
      ) : null}
    </>
  );
};

export default Aboutus;
